// @flow

import React from 'react';

type Props = {
  ['html' | 'content']: string,
};

const Content = (props: Props): React$Element<'section'> => {
  const {html, content} = props;

  if (html) {
    return (
      <section
        // eslint-disable-next-line react/no-danger -- we specifically want to present this as HTML
        dangerouslySetInnerHTML={{__html: html}}
        itemProp="articleBody"
      />
    );
  }

  return <section>{content}</section>;
};

export default Content;
