// @flow

import React from 'react';
import styled from 'styled-components';

import Content from 'components/Content';
import Header from 'components/Header';
import Layout from 'components/Layout';
import Link from 'components/Link';
import Logo from 'components/Logo';
import strings from 'config/strings.json';
import {rhythm} from 'utils/typography';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: ${rhythm(1 / 2)};
  text-align: center;

  svg {
    margin-bottom: ${rhythm(1 / 2)};
  }

  a {
    margin-top: ${rhythm(1)};
  }
`;

const Page404 = (): React$Element<typeof Layout> => {
  const {content, header, pageMeta, back} = strings.pages['404'];
  return (
    <Layout path="/404" pageMeta={pageMeta}>
      <Wrapper>
        <Logo />
        <Header title={header} />
        <Content content={content} />
        <Link to="/">{back}</Link>
      </Wrapper>
    </Layout>
  );
};

export default Page404;
